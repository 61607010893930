import React from "react";
import copy from "clipboard-copy";
import { useTranslation } from "react-i18next";
import "../static/style.css";
import { contact_email, contact_phone } from "../const/const_team_members";

const SectionFooter = () => {
  const [t] = useTranslation("translation");

  const copyText = (text: string | undefined) => {
    if (text) {
      copy(text);
    }
  };

  return (
    <footer>
      <div id="footer">
        <div className="footer--left">
          <img src="/icons/black_second_logo.svg" alt="" />
        </div>
        <div className="footer--center">
          <div className="footer--center-container">
            <span className="center--container-header">
              {t("contact_phone")}
            </span>
            <a
              href={`tel:${contact_phone}`}
              className="center--container-phone"
              onClick={() => {
                copyText(contact_phone);
              }}
            >
              {contact_phone}
            </a>
            <span className="center--container-header">
              {t("contact_email")}
            </span>
            <a
              href={`mailto:${contact_email}`}
              className="center--container-email"
              onClick={() => {
                copyText(contact_email);
              }}
            >
              {contact_email}
            </a>
            <span className="center--container-header">
              {t("contact_adress_title")}
            </span>
            <a
              href="https://www.google.com/maps/place/Veskiposti+2,+10138+Tallinn,+Estonia/@59.4185169,24.756651,17z/data=!4m6!3m5!1s0x469294bafe1459dd:0xc4b3f0abe5e26415!8m2!3d59.4185539!4d24.7566435!16s%2Fg%2F11y3nnr8x0?entry=ttu"
              target="_blank"
              rel="noreferrer"
              className="center--container-adress"
            >
              {t("contact_adress_descr_talin")}
              <br />
              {/*{t("filial")}: {t("contact_adress_descr_kiev")}*/}
            </a>
          </div>
        </div>
        <div className="footer--right">
          <span className="footer--right-text">{t("copywriting_text")}</span>
          <p className="footer--right-copywriting">
            {t("copywriting")} {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};
export default SectionFooter;
