import React from "react";
import SectionHeader from "../sections/SectionHeader";
import SectionAboutUs from "../sections/SectionAboutUs";
import SectionTeam from "../sections/SectionTeam";
import SectionFooter from "../sections/SectionFooter";
import SectionProjects from "../sections/SectionProjects";
import AppBarLendingWithMob from "../components/AppBarLendingWithMob";
import SectionFeedback from "../sections/SectionFeedback";
import ContactUs from "../components/ContactUs";

const Page = () => {
  return (
    <>
      {/*<AppBarLending/>*/}
      <AppBarLendingWithMob />
      {/*<AppBarCss/>*/}
      <SectionHeader />
      <SectionAboutUs />
      {/*<SectionServicesWithTech/>*/}
      <SectionProjects />
      <SectionTeam />
      {/* <SectionFeedback/> */}
      <ContactUs />
      <SectionFooter />
    </>
  );
};

export default Page;
