import * as React from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


const Popup = () => {
    const [t,] = useTranslation('translation');

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    return <BootstrapDialog
        onClose={handleClose}

        aria-labelledby="customized-dialog-title"
        open={open}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("starter_team")}
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Box
                component="img"
                sx={{
                    // width:450,
                    maxWidth: {xs: '100%'},
                    maxHeight: {xs: 500}
                }}
                alt={t("starter_team")}
                src="/img.png"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>
                {t("close")}
            </Button>
        </DialogActions>
    </BootstrapDialog>
}
export default Popup;