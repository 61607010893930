import { PropsCardTeamMember } from "../types/card_member";

export const contact_email: string = "info@semiotics-lab.com";
export const contact_phone: string = "+37254726673";
const team_members: Array<PropsCardTeamMember> = [
  {
    imgPath: "/team/face_4.png",
    keySpecialization: "potseluev_specialization",
    keyMemberName: "potseluev_member_name",
    keyMemberDescription: "potseluev_description",
    contacts: {
      email: "basil.potseluev@semiotics-lab.com",
      linkedin: "http://linkedin.com/in/vasiliy-potseluev-952186121",
      //   phone: "+380 (66) 604 64 23",
    },
  },
  {
    imgPath: "/team/face_3.png",
    keySpecialization: "shchutskyi_specialization",
    keyMemberName: "shchutskyi_member_name",
    keyMemberDescription: "shchutskyi_description",
    contacts: {
      email: "denis.shchutskyi@semiotics-lab.com",
      linkedin: "https://www.linkedin.com/in/denis-shchutskyi-094163142/",
      //   phone: "+380 (97) 949 10 74",
    },
  },
  // {
  //     imgPath: "/team/face_2.png",
  //     keySpecialization: "lytvynenko_specialization",
  //     keyMemberName: "lytvynenko_member_name",
  //     keyMemberDescription: "lytvynenko_description",
  //     contacts: {
  //         email: "lytvynenkotv@gmail.com",
  //         linkedin: "https://www.linkedin.com/in/tatiana-lytvynenko/",
  //         phone: "+380 (93) 949 28 19",
  //     }
  // },
  //   {
  //     imgPath: "/team/face_5.png",
  //     keySpecialization: "sargsian_specialization",
  //     keyMemberName: "sargsian_member_name",
  //     keyMemberDescription: "sargsian_description",
  //     contacts: {
  //       linkedin: "https://www.linkedin.com/in/lilith-sargsyan-/",
  //       phone: "+37495090121",
  //     },
  //   },
  // {
  //     imgPath: "/team/face_1.png",
  //     keySpecialization: "liashenko_specialization",
  //     keyMemberName: "liashenko_member_name",
  //     keyMemberDescription: "liashenko_description",
  //     contacts: {
  //         email: "sergliashenko@gmail.com",
  //         linkedin: "https://www.linkedin.com/in/sergeyliashenko/",
  //         phone: "+380 (93) 405 57 88",
  //     }
  // },
  // {
  //     imgPath: "/team/face_1.png",
  //     keySpecialization: "utkin_specialization",
  //     keyMemberName: "utkin_member_name",
  //     keyMemberDescription: "utkin_description",
  //     contacts: {
  //         linkedin: "https://www.linkedin.com/in/yurii-utkin-17139b235/",
  //         email: "yur.utkin02@gmail.com",
  //         phone: "+380953810270"
  //     }
  // },
  // {
  //     imgPath: "/team/face_1.png",
  //     keySpecialization: "kizitskyi_specialization",
  //     keyMemberName: "kizitskyi_member_name",
  //     keyMemberDescription: "kizitskyi_description",
  //     contacts: {
  //         email: "mkizitskyi@gmail.com",
  //         linkedin: "https://www.linkedin.com/in/maksym-kizitskyi-44688b236/",
  //         phone: "+380660578210"
  //     }
  // },
  // {
  //     imgPath: "/team/face_1.png",
  //     keySpecialization: "kontseus_specialization",
  //     keyMemberName: "kontseus_member_name",
  //     keyMemberDescription: "kontseus_description",
  //     contacts: {
  //         email: "dn010867kaj@gmail.com",
  //         linkedin: "https://www.linkedin.com/in/aleksandr-kontseus-522662165/",
  //         phone: "+380671957383"
  //     }
  // },
];
export default team_members;
