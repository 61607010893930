import { InterfaceCardProject } from "../types/card_project";

const projects: Array<InterfaceCardProject> = [
  {
    filePath: "/pdf/Message_Tagger.pdf",
    imgPath: "/projects/swarm.webp",
    keyTitleProject: "project_message_tagger",
    keyUnderTitleProject: "project_under_message_tagger",
    keyDescriptionProject: "project_message_tagger_description",
  },
  {
    filePath: "/pdf/Turing.pdf",
    imgPath: "/projects/navigation.jpg",
    keyTitleProject: "project_turing",
    keyUnderTitleProject: "project_under_turing",
    keyDescriptionProject: "project_turing_description",
  },
  {
    filePath: "/pdf/Personalizer_MVP.pdf",
    imgPath: "/projects/industrial.jpg",
    keyTitleProject: "project_personalizer",
    keyUnderTitleProject: "project_under_personalizer",
    keyDescriptionProject: "project_personalizer_description",
  },
  {
    filePath: "/pdf/Wishmaster_MVP.pdf",
    imgPath: "/projects/remote.jpg",
    keyTitleProject: "project_wish_master",
    keyUnderTitleProject: "project_under_wish_master",
    keyDescriptionProject: "project_wish_master_description",
  },
  {
    filePath: "#",
    imgPath: "/projects/detection.avif",
    keyTitleProject: "project_sound_processing",
    keyUnderTitleProject: "project_under_sound_processing",
    keyDescriptionProject: "project_sound_processing_description",
    // underDevelopment: true,
  },
  {
    filePath: "/pdf/Поиск.pdf",
    imgPath: "/projects/uav.webp",
    keyTitleProject: "project_search",
    keyUnderTitleProject: "project_under_search",
    keyDescriptionProject: "project_search_description",
    // underDevelopment: true,
  },
  {
    filePath: "/pdf/Поиск.pdf",
    imgPath: "/projects/image_6.png",
    keyTitleProject: "project_benchmark_analysis",
    keyUnderTitleProject: "project_under_benchmark_analysis",
    keyDescriptionProject: "project_benchmark_analysis_description",
    // underDevelopment: true,
  },
];

export default projects;
